<template>
  <div v-if="id">
    <div class="d-flex flex-row align-center">
      <user-ref
        :avatar-props="{normal: true}"
        :id="id"
        block-popup
        hide-group
        hide-email
      />
    </div>
  </div>
</template>

<script>

export default {
  components: {
    UserRef: () => import('@/components/documents-ref/user-ref')
  },
  computed: {
    id () {
      return this.document?.id
    }
  },
  props: {
    document: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="stylus" scoped>
.header
  width 400px
</style>
